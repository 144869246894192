.footer {
    background-color: #333; /* Dark background color */
    color: #fff; /* White text */
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between; /* Spaces the items apart */
    align-items: center; /* Centers the items vertically */
    max-width: 800px; /* Optional: limits the width for better readability */
    margin: 0 auto; /* Centers the content within the footer */
}

.footer-content p {
    margin: 0; /* Removes extra margins */
    font-size: 14px;
}
