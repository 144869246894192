* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Basic Body Styling */
  body {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #333;
    background-color: #f4f4f8;
    line-height: 1.6;
  }
  
  /* General Layout */
  .container {
    width: 100%;
    /*max-width: 1200px;*/
    margin: 0 auto;
    padding: 2rem 0;
  }
  
  /* Hero Section */
  .hero {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background: linear-gradient(to right, #444, #666);
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    padding-top: 10px;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  
  .hero button {
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .hero button:hover {
    background-color: #ff4757;
  }
  
  /* Section Headers */
  h2 {
    font-size: 2rem;
    color: #333;
    text-align: center;
  }
  
  /* Services Section */
  .services {
    background-color: #fff;
    padding: 3rem 0;
  }
  
  .services .service-item {
    padding: 1.5rem;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: box-shadow 0.3s;
  }
  
  .services .service-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .services h3 {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
    color: #444;
  }
  
  /* About Section */
  .about {
    background-color: #fafafa;

    text-align: center;
    color: #555;
  }
  
  .about p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
  
  /* Portfolio Section */
  .portfolio {
    background-color: #fff;
    padding: 3rem 0;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 2rem;
  }
  
  .portfolio img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Contact Section */
  .contact {
    background-color: #f4f4f8;
    padding: 3rem 0;
    text-align: center;
  }
  
  .contact form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .contact input,
  .contact textarea {
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact button {
    padding: 0.8rem;
    font-size: 1rem;
    color: #fff;
    background-color: #333;
    border: none;
    cursor: pointer;
  }
  
  .contact button:hover {
    background-color: #555;
  }
  /* Portfolio Section Styling */
.portfolio {
    background-color: #fff;
    padding: 3rem 0;
  }
  
  .portfolio-item {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .portfolio-images {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .portfolio img {
    width: 45%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio p {
    max-width: 800px;
    margin: 0 auto;
    color: #555;
    font-size: 1rem;
  }
/* Navbar Styling */
.navbar {
    background-color: #333;
    color: #fff;
    height: 40px; /* Set a fixed height for the navbar */
    padding: 0; /* Remove internal padding to rely only on height */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center; /* Vertically center items */
  }
  
  .navbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  
 
  .navbar-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 1.6rem;
  }
  
  .quote-button {
    padding: 0.2rem 0.5rem;
    background-color: #ff6b6b;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.85rem;
  }
  
  .quote-button:hover {
    background-color: #ff4757;
  }
.testimonials {
    background-color: #f9f9f9;
    padding: 3rem 0;
    text-align: center;
  }
  
  .testimonials h2 {
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .testimonial-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .testimonial-item {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .testimonial-item p {
    color: #555;
    font-style: italic;
  }
  