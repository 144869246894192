/* src/index.css */

/* Reset some default browser styling */
* {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    line-height: 1.6;
  }
  