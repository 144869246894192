.top, .bottom {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.imageT {
    max-height: 600px;
    display: flex;
    justify-content: flex-end; /* Aligns content to the end on full screen */
    height: 600px;
    width: auto;
}

.imageTT {
    display: flex;
    justify-content: flex-start; /* Aligns content to the start on full screen */
    height: 600px;
    width: auto;
}

.bottom {
    border-top: 1px solid grey;
}
.TitleP {
    margin-top: 20px;
    font-size: 36px;
    font-weight: 700;
    color: #333;
}
.SubtitleP {
    font-size: 28px;
    color: #333;
}
.TextP {
    padding: 20px;
    font-weight: 300;
    font-size: 16px;
    color: #333;
}  
/* Responsive styles */
@media (max-width: 768px) {
    .top, .bottom {
        flex-direction: column;
        height: auto;
    }

    .textT, .imageT, .imageTT {
        width: 100%;
        height: auto;
        text-align: center;
        justify-content: center; /* Center-aligns content on smaller screens */
    }

    .imageF {
        height: auto;
        max-height: 100%;
    }

    .Timage {
        height: auto;
        max-width: 100%;
    }

    .TitleP {
        font-size: 28px;
        margin-top: 10px;
    }

    .SubtitleP {
        font-size: 24px;
    }

    .TextP {
        padding: 10px;
        font-size: 14px;
    }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
    .TitleP {
        font-size: 24px;
    }

    .SubtitleP {
        font-size: 20px;
    }

    .TextP {
        font-size: 12px;
        padding: 8px;
    }
}
